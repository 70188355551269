import { Component, OnInit } from '@angular/core';
import { Profile } from "../model/profile";
import { ProfileService } from "../core/services/profile.service";
import { CvService } from "../core/services/cv.service";
import { CurriculumVitaeSpecification } from "../model/curriculumVitaeSpecification";
import { UserService } from "../core/services/user.service";
import { User } from "../model/user";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { Companies } from '../model/company.model';
import { map, switchMap } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { SelectOption } from '../core/components/basic/select/select-option.model';
import { SelectComponent } from '../core/components/basic/select/select.component';
import { LabelComponent } from '../core/components/basic/label/label.component';
import { FormSelectComponent } from '../core/components/form/form-select/form-select.component';
import { FormCheckboxComponent } from '../core/components/form/form-checkbox/form-checkbox.component';
import { FormAssignmentsComponent } from '../core/components/form/form-assignments/form-assignments.component';
import { FormButtonsComponent } from '../core/components/form/form-buttons/form-buttons.component';

@Component({
  selector: 'app-download',
  standalone: true,
  imports: [
    AsyncPipe,
    FormAssignmentsComponent,
    FormButtonsComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    LabelComponent,
    ReactiveFormsModule,
    SelectComponent
],
  templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {
  public static readonly MAX_NR_OF_DETAILED_ASSIGNMENTS = 5;

  public languageOptions: SelectOption<string>[] = [
    { value: 'DUTCH', label: $localize`Dutch` },
    { value: 'ENGLISH', label: $localize`English` }
  ];

  public templateOptions = Companies.getAllCompanyOptions();

  public data$: Observable<Data>;
  public errorMessage = '';
  public isGenerating = false;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private cvService: CvService) {
  }

  public ngOnInit(): void {
    this.data$ = combineLatest([this.getAvailableUsers(), this.profileService.getActiveProfile()]).pipe(
      map(([availableUsers, activeProfile]) => ({
        profileId: activeProfile.id,
        form: this.createForm(activeProfile),
        selectedProfile: activeProfile,
        options: this.createOptions(availableUsers, activeProfile)
      }))
    );
  }

  public onProfileSelectionChange(profileId: string): void {
    if (profileId) {
      this.profileService.setActiveProfile(profileId);
    }
  }

  public generateCV(data: Data): void {
    this.errorMessage = '';
    this.isGenerating = true;
    data.form.disable();

    const assignmentsValue = data.form.value['assignments'] as boolean[];
    const assignmentIds = assignmentsValue
      .map((selected, idx) => ({
        assignmentId: data.selectedProfile.assignments[idx].id,
        selected: selected
      }))
      .filter(item => item.selected)
      .map(item => item.assignmentId);

    const maxNrOfDetailedAssignments = Math.min(assignmentIds.length, data.form.value['maxNrOfDetailedAssignments']);

    const cv: CurriculumVitaeSpecification = {
      profileId: data.profileId,
      language: data.form.value['language'],
      summaryId: data.form.value['summary'],
      company: data.form.value['template'],
      usePhoto: data.form.value['usePhoto'],
      selectAssignments: assignmentIds,
      maxEducation: parseInt(data.form.value['maxNrOfEducations']),
      maxCertificates: parseInt(data.form.value['maxNrOfCertificates']),
      maxAssignmentsDetail: maxNrOfDetailedAssignments,
      maxAssignmentsSummary: assignmentIds.length - maxNrOfDetailedAssignments
    };

    this.cvService
      .generateCV(cv)
      .subscribe({
        next: blob => {
          this.downloadFile(data.selectedProfile, blob);
          data.form.enable();
          this.isGenerating = false;
        },
        error: err => {
          console.log(err)
          this.errorMessage = $localize`Something went wrong while downloading the CV. Please contact the system administrator. ${err.message}`;
          data.form.enable();
          this.isGenerating = false;
        }
      });
  }

  private createForm(selectedProfile: Profile): FormGroup {
    return new FormGroup({
      language: new FormControl(this.languageOptions[0].value, Validators.required),
      template: new FormControl(selectedProfile.professional.company, Validators.required),
      usePhoto: new FormControl({
        value: selectedProfile.professional.usePhoto,
        disabled: !selectedProfile.professional.usePhoto
      }),
      summary: new FormControl(selectedProfile.summaries.length > 0 ? selectedProfile.summaries[0].id : null, Validators.required),
      assignments: new FormArray(selectedProfile.assignments.map(_ => new FormControl(true))),
      maxNrOfDetailedAssignments: new FormControl(Math.min(selectedProfile.assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS), Validators.required),
      maxNrOfEducations: new FormControl(selectedProfile.educations.length, Validators.required),
      maxNrOfCertificates: new FormControl(selectedProfile.certificates.length, Validators.required)
    });
  }

  private createOptions(availableUsers: User[], activeProfile: Profile): Options {
    return {
      profiles: availableUsers.map(user => ({
        value: user.profileId,
        label: `${user.name } (${ Companies.companyToName(user.company) })`
      })),
      summaries: activeProfile.summaries.map(summary => ({
        value: summary.id,
        label: summary.name
      })),
      maxNrOfDetailedAssignments: this.numberOptions(activeProfile.assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS),
      maxNrOfEducations: this.numberOptions(activeProfile.educations.length),
      maxNrOfCertificates: this.numberOptions(activeProfile.certificates.length)
    };
  }

  private getAvailableUsers(): Observable<User[]> {
    return this.userService.getCurrent().pipe(
      switchMap(user => {
        if (!user.isSales) {
          return of(([user]));
        } else {
          return combineLatest([this.userService.getAll(), this.profileService.getAvailableProfileIds()]).pipe(
            map(([users, profileIds]) => {
              return users
                .filter(user => profileIds.indexOf(user.profileId) > -1)
                .sort((user1, user2) => user1.name.localeCompare(user2.name))
            })
          );
        }
      })
    );
  }

  private numberOptions<T>(length: number, maxLength?: number | null): SelectOption<number>[] {
    const limitedLength = maxLength ? Math.min(length, maxLength) : length;
    const actualLength = limitedLength + 1; // Add 1 for the value '0'.

    return Array(actualLength)
      .fill(null)
      .map((_, idx: number) => ({
        value: idx,
        label: idx.toString()
      }));
  }

  private getCurrentTimestamp(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}`;
  }

  private downloadFile(selectedProfile: Profile, blob: Blob): void {
    const url = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = `CV ${selectedProfile.professional.fullName} - ${this.getCurrentTimestamp()}.docx`;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
}

interface Options {
  profiles: SelectOption<string>[];
  summaries: SelectOption<string>[];
  maxNrOfDetailedAssignments: SelectOption<number>[],
  maxNrOfEducations: SelectOption<number>[],
  maxNrOfCertificates: SelectOption<number>[]
}

interface Data {
  profileId: string;
  form: FormGroup;
  selectedProfile: Profile | null;
  options: Options
}